import React from 'react';

import { BrowserRouter, Route, Switch } from "react-router-dom";

import Emutabakat from './e-mutabakat';

const EMutabakatUygulama = () => {
    return <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Emutabakat}></Route>
            <Route exact path="/:musteri_id/:belge_id/" component={Emutabakat}></Route>
            <Route exact path="/:musteri_id/:belge_id/:cevap" component={Emutabakat}></Route>
            <Route component={Emutabakat}></Route>
        </Switch>
    </BrowserRouter>
};

export default EMutabakatUygulama;