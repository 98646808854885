import { faCog, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import publicIp from "public-ip";
import React, { Component } from "react";
import renderHTML from "react-render-html";
import "./styles.css";

class EMutabakat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      belge: {
        alici_unvan: "",
        alici_vergi_dairesi: "",
        alici_vergi_numarasi: "",
        cari_bakiye: "",
        gonderen_unvan: "",
        gonderen_vergi_dairesi: "",
        gonderen_vergi_numarasi: "",
        not: "",
        hesap_tipi: "",
        para_birimi: "",
        tarih: "",
      },
      dosya: "",
      dosya_dizin: "",
      dosya_tipleri: ["doc", "docx", "gif", "jpg", "jpeg", "pdf", "png", "xls", "xlsx", "zip"],
      dosya_yukleniyor: false,
      dosya_yukleme_hatasi: "",
      durum: 3, // 0: Sonuç (Belge yok), 1: Sonuç (Cevaplandı), 2: Sonuç (Hata oluştu), 3:Yükleniyor, 4: Belge görüntüleme, 5: Daha önceden cevaplandi
      ip: "",
      isim: "",
      isim_alani_zorunlu: true,
      servis: process.env.REACT_APP_API_URL_BASE || "https://api.dijitalgozluk.com/emutabakat",
      servis_v2: process.env.REACT_APP_API_URL_BASE_V2 || "https://api.dijitalgozluk.com/v2",
      yazilar: {
        tr: {
          alacakli: "Alacaklı",
          alici_unvan: "Alıcı",
          alici_vergi_dairesi: "Alıcı Vergi Dairesi",
          alici_vergi_numarasi: "Alıcı Vergi Numarası",
          borclu: "Borçlu",
          cari_bakiye: "Cari Bakiye",
          duruma_gore_yazilar: {
            durum_0: {
              baslik: "Belge bulunamadı.",
              mesaj: "Görüntülemek istediğiniz belge bulunamadı.",
            },
            durum_1: {
              baslik: "Teşekkürler",
              mesaj: "Mutabakata verdiğiniz cevap iletildi.",
            },
            durum_2: {
              baslik: "Hata",
              mesaj: "Bilgiler yükleniyorken daha oluştu.",
            },
            durum_4: {
              baslik: "Cari Mutabakat",
              mesaj: " Tarihli Mutabakatınız",
            },
            durum_5: {
              baslik: "Bilgilendirme",
              mesaj: "Daha önce cevap verilen bir mutabakat için tekrar gönderim yapılmış. Herhangi bir işlem yapılmadı",
            },
          },
          gerekli_alan: "(Gerekli alan)",
          gonderen_unvan: "Gönderen",
          gonderen_vergi_dairesi: "Gönderen Vergi Dairesi",
          gonderen_vergi_numarasi: "Gönderen Vergi Numarası",
          hatalar: {
            dosya_boyutu: "Dosya en fazla 10 mb. olabilir.",
            dosya_tipi: "Dosya tipi DOC, DOCX, GIF, JPG, JPEG, PDF, PNG, XLS, XLSX ya da ZIP olabilir.",
          },
          hesap_tipi: "Hesap Tipi",
          isim_soyisim: "Adınız Soyadınız",
          isim_soyisim_zorunlu: "Adınızı ve soyadınızı belirtmeniz gerekiyor.",
          mutabikiz: "Mutabıkız",
          mutabik_degiliz: "Mutabık Değiliz",
          notlar: `
                        1. Mutabakatınızı bir ay içerisinde bildirmediğiniz takdirde T.T.K nun 92.Madddesi gereğince mutabık sayilacağımızı hatırlatırız.<br />
                        2. Bakiyede mutabık olmadığınız takdirde hesap ekstrenizi tarafımıza acilen ileterek bu formu reddedebilirsiniz.<br />
                        3. Hata ve unutma müstesnadır.<br />
                        4. Bu mutabakat yazısı elektronik ortam gönderilmektedir.<br />
                        5. Lütfen Adres ve iletişim bilgilerinizdeki değişiklilerinizi tarafimiza bildiriniz.<br />
                    `,
        },
      },
    };
  }

  async componentDidMount() {
    const { belge_id, cevap, musteri_id } = this.props.match.params;

    const _ip = await this.ipBilgisiniAl();
    this.setState({ ip: _ip });
    // console.log('EMutabakat componentDidMount _ip', _ip);

    if (musteri_id === "IST") {
      this.setState({ isim_alani_zorunlu: true });
    }

    try {
      if (axios !== undefined) {
        if (belge_id !== undefined && musteri_id !== undefined && cevap === undefined) {
          await this.mutabakatBilgileriniGetir({ belge_id, musteri_id });
        }
        if (belge_id !== undefined && musteri_id !== undefined && cevap !== undefined) {
          if (cevap === "onay" || cevap === "red") {
            const _cevap = cevap === "onay" ? "1" : "2";
            await this.mutabakataCevapVer({ belge_id, cevap: _cevap, musteri_id });
          } else {
            await this.mutabakatBilgileriniGetir({ belge_id, musteri_id });
          }
        }
        if (belge_id === undefined && musteri_id === undefined && cevap === undefined) {
          this.setState({ durum: 0 });
        }
      }
    } catch (err) {
      this.setState({ durum: 2 });
      console.log(err);
    }
  }

  componentDidUpdate() {
    window.state = this.state;
    window.setState = this.setState;
  }

  ipBilgisiniAl = async () => {
    const _ip = await publicIp.v4();
    return _ip;
  };

  dosyaYukle = async () => {
    try {
      let _form_data = new FormData();
      _form_data.append("proje", "emutabakat");
      _form_data.append("dosya", this.state.dosya);
      _form_data.append("sirket", this.props.match.params.musteri_id);
      _form_data.append("id", this.props.match.params.belge_id);

      // console.log('dosyaYukle this.state.dosya',this.state.dosya);
      // if (!this.state.dosya_tipleri.map(dosya_tipi => `application/${dosya_tipi}`).includes(`${this.state.dosya.type}`)) {
      //     throw new Error(this.state.yazilar.tr.hatalar.dosya_tipi);
      // }
      if (this.state.dosya.size >= 1048576) {
        throw new Error(this.state.yazilar.tr.hatalar.dosya_boyutu);
      }

      console.log("EMutabakat dosyaYukle this.state.dosya _form_data", this.state.dosya, [..._form_data.values()]);

      const _dosya = await axios.post(`${this.state.servis_v2}/dosya`, _form_data, { "Content-Type": "multipart/form-data" });
      console.log("EMutabakat dosyaYukle _dosya event.target.files[0] this.state.servis_v2/dosya this.state.dosya", _dosya, this.state.dosya, `${this.state.servis_v2}/dosya`, this.state.dosya);

      if (_dosya.data.errors) {
        console.log("EMutabakat dosyaYukle _dosya.data.errors", _dosya.data.errors);
        throw new Error(_dosya.data.errors[0] ? _dosya.data.errors[0].detail : "Belirlenemeyen bir hata oluştu.");
      }

      this.setState({ dosya_dizin: _dosya.data.indirme_baglantisi });
    } catch (error) {
      this.setState({ dosya_yukleme_hatasi: error.message });
      console.error(`EMutabakat dosyaYukle error.message: ${error.message}`);
    } finally {
      this.setState({ dosya_yukleniyor: false });
    }
  };

  mutabakataCevapVer = ({ belge_id, cevap, musteri_id }) => {
    try {
      this.setState({ durum: 3 }, () => {
        let _form_data = new FormData();
        _form_data.append("aciklama", this.state.belge.not || "");
        _form_data.append("id", belge_id);
        _form_data.append("sirket", musteri_id);
        _form_data.append("sonuc", cevap);
        _form_data.append("ip", this.state.ip);
        _form_data.append("isim", this.state.isim);
        _form_data.append("dosya", this.state.dosya_dizin);

        // console.log('EMutabakat mutabakataCevapVer _form_data', [..._form_data.values()]);

        axios
          .post(`${this.state.servis}/sonuc`, _form_data, { aciklama: this.state.belge.not, belge_id, cevap, musteri_id })
          .then((r) => {
            // console.log("r", [...r.config.data.values()]);
            switch (r.status) {
              case 200:
                switch (r.data) {
                  case "0":
                    this.setState({ durum: 0 });
                    break;
                  case "1":
                    this.setState({ durum: 1 });
                    break;
                  case "2":
                    this.setState({ durum: 5 }, () => console.log("case22"));
                    break;
                  default:
                    console.log("mutabakataCevapVer default");
                    this.setState({ durum: 2 });
                }
                break;
              case 204:
                this.setState({ durum: 0 });
                break;
              default:
                this.setState({ durum: 2 });
                break;
            }
          })
          .catch((err) => {
            this.setState({ durum: 2 });
            console.log("mutabakataCevapVer /sonuc", err);
          });
      });
    } catch (err) {
      this.setState({ durum: 2 });
      console.log("mutabakataCevapVer", err);
    }
  };
  mutabakatBilgileriniGetir = async ({ belge_id, musteri_id }) => {
    try {
      let _form_data = new FormData();
      _form_data.append("sirket", musteri_id);
      _form_data.append("id", belge_id);

      axios
        .post(`${this.state.servis}/goruntule`, _form_data, { sirket: musteri_id, id: belge_id })
        .then((r) => {
          const _belge = r.data;
          switch (r.status) {
            case 200:
              this.setState({
                belge: {
                  alici_unvan: _belge.alici.unvan,
                  alici_vergi_dairesi: _belge.alici.vergi_dairesi,
                  alici_vergi_numarasi: _belge.alici.vergi_no,
                  cari_bakiye: new Intl.NumberFormat("tr-TR", { minimumIntegerDigits: 1, minimumFractionDigits: 2 }).format(_belge.cari_bakiye),
                  gonderen_unvan: _belge.gonderen.unvan,
                  gonderen_vergi_dairesi: _belge.gonderen.vergi_dairesi,
                  gonderen_vergi_numarasi: _belge.gonderen.vergi_no,
                  hesap_tipi: _belge.tip,
                  para_birimi: _belge.para_birimi,
                  tarih: _belge.tarih,
                  tarih2: _belge.tarih2,
                },
                durum: 4,
                yazilar: {
                  ...this.state.yazilar,
                  tr: {
                    ...this.state.yazilar.tr,
                    cari_bakiye: _belge.bakiye_tipi_baslik,
                    duruma_gore_yazilar: {
                      ...this.state.yazilar.tr.duruma_gore_yazilar,
                      durum_4: {
                        baslik: _belge.baslik,
                        mesaj: _belge.tarih,
                      },
                    },
                    hesap_tipi: _belge.hesap_tipi_baslik,
                  },
                },
              });
              break;
            case 204:
              this.setState({ durum: 0 });
              break;
            default:
              this.setState({ durum: 2 });
              break;
          }
        })
        .catch((err) => {
          this.setState({ durum: 2 });
        });
    } catch (err) {
      this.setState({ durum: 2 });
      console.log(err);
    }
  };
  olmayanGorsel = (err) => {
    console.log(err.target.src);
    err.target.src = "https://emutabakat.dijitalgozluk.com/gorseller/logo-emutabakat.png";
  };
  sayfaDurumu = () => {
    switch (this.state.durum) {
      case 0:
        return this._sonuc(0);
      case 1:
        return this._sonuc(1);
      case 2:
        return this._sonuc(2);
      case 3:
        return this._yukleniyor(3);
      case 4:
        return this._belge(4);
      case 5:
        return this._sonuc(5);
      default:
        return this._sonuc(2);
    }
  };
  render() {
    return this.sayfaDurumu();
  }
  _belge = () => {
    const { tr: _yazilar } = this.state.yazilar;
    const { belge: _belge } = this.state;

    return (
      <table border="0" cellPadding="0" cellSpacing="0" width="100%" className="ana_tablo">
        <tbody>
          <tr>
            <td bgcolor="#ffffff" align="center" className="ana_tablo_arkaplan">
              <table border="0" cellPadding="0" cellSpacing="0" width="100%" className="icerik_tablosu wrapper">
                <tbody>
                  <tr>
                    <td align="center" valign="top" className="icerik_tablosu_bosluklari logo">
                      <img alt="Logo" src={`https://emutabakat.dijitalgozluk.com/gorseller/logo-${this.props.match.params.musteri_id}.png`} onError={this.olmayanGorsel} className="logo-gorseli" border="0" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td bgcolor="#ffffff" align="center" className="sayfa_basligi_tablosu_disi">
              <table border="0" cellPadding="0" cellSpacing="0" width="100%" className="sayfa_basligi_tablosu responsive-table">
                <tbody>
                  <tr>
                    <td>
                      <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                          <tr>
                            <td align="center" className="sayfa_basligi_tablosu_ana_baslik padding-copy">
                              {_yazilar.duruma_gore_yazilar["durum_" + this.state.durum].baslik}
                            </td>
                          </tr>
                          <tr>
                            <td align="center" className="sayfa_basligi_tablosu_alt_baslik padding-copy">
                              {_belge.tarih}
                              {/* _yazilar.duruma_gore_yazilar["durum_" + this.state.durum].mesaj */}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td bgcolor="#ffffff" align="center" className="bilgiler_tablosu_disi padding">
              <table border="0" cellPadding="0" cellSpacing="0" width="100%" className="bilgi_alani_tablolar responsive-table">
                <tbody>
                  <tr>
                    <td className="bilgi_alani_ust_sinir">
                      <table cellSpacing="0" cellPadding="0" border="0" width="100%">
                        <tbody>
                          <tr>
                            <td valign="top" className="bilgi_alani_ust_sinir_ici mobile-wrapper">
                              <table cellPadding="0" cellSpacing="0" border="0" width="47%" className="bilgi_alani_yuzde_47" align="left">
                                <tbody>
                                  <tr>
                                    <td className="bilgi_alani_yazi_tablosu_disi">
                                      <table cellPadding="0" cellSpacing="0" border="0" width="100%">
                                        <tbody>
                                          <tr>
                                            <td align="left" className="bilgi_alani_yazi_baslik">
                                              {_yazilar.gonderen_unvan}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table cellPadding="0" cellSpacing="0" border="0" width="47%" className="bilgi_alani_yuzde_47" align="right">
                                <tbody>
                                  <tr>
                                    <td className="bilgi_alani_yazi_tablosu_disi">
                                      <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="bilgi_alani_sifirlama">
                                        <tbody>
                                          <tr>
                                            <td align="right" className="bilgi_alani_yazi_baslik">
                                              {_belge.gonderen_unvan}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table cellSpacing="0" cellPadding="0" border="0" width="100%" className="bilgi_alani_sifirlama">
                        <tbody>
                          <tr>
                            <td valign="top" className="bilgi_alani_yazi_baslik_disi mobile-wrapper">
                              <table cellPadding="0" cellSpacing="0" border="0" width="47%" className="bilgi_alani_yuzde_47" align="left">
                                <tbody>
                                  <tr>
                                    <td className="bilgi_alani_yazi_tablosu_disi">
                                      <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="bilgi_alani_sifirlama">
                                        <tbody>
                                          <tr>
                                            <td align="left" className="bilgi_alani_yazi_baslik">
                                              {_yazilar.gonderen_vergi_dairesi}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table cellPadding="0" cellSpacing="0" border="0" width="47%" className="bilgi_alani_yuzde_47" align="right">
                                <tbody>
                                  <tr>
                                    <td className="bilgi_alani_yazi_tablosu_disi">
                                      <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="bilgi_alani_sifirlama">
                                        <tbody>
                                          <tr>
                                            <td align="right" className="bilgi_alani_yazi_baslik">
                                              {_belge.gonderen_vergi_dairesi}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table cellSpacing="0" cellPadding="0" border="0" width="100%" className="bilgi_alani_sifirlama">
                        <tbody>
                          <tr>
                            <td valign="top" className="bilgi_alani_yazi_baslik_disi mobile-wrapper">
                              <table cellPadding="0" cellSpacing="0" border="0" width="47%" className="bilgi_alani_yuzde_47" align="left">
                                <tbody>
                                  <tr>
                                    <td className="bilgi_alani_yazi_tablosu_disi">
                                      <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="bilgi_alani_sifirlama">
                                        <tbody>
                                          <tr>
                                            <td align="left" className="bilgi_alani_yazi_baslik">
                                              {_yazilar.gonderen_vergi_numarasi}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table cellPadding="0" cellSpacing="0" border="0" width="47%" className="bilgi_alani_yuzde_47" align="right">
                                <tbody>
                                  <tr>
                                    <td className="bilgi_alani_yazi_tablosu_disi">
                                      <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="bilgi_alani_sifirlama">
                                        <tbody>
                                          <tr>
                                            <td align="right" className="bilgi_alani_yazi_baslik">
                                              {_belge.gonderen_vergi_numarasi}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table border="0" cellPadding="0" cellSpacing="0" width="100%" className="bilgi_alani_tablolar responsive-table">
                <tbody>
                  <tr>
                    <td className="bilgi_alani_bolum_2">
                      <table cellSpacing="0" cellPadding="0" border="0" width="100%">
                        <tbody>
                          <tr>
                            <td valign="top" className="bilgi_alani_ust_sinir_ici mobile-wrapper">
                              <table cellPadding="0" cellSpacing="0" border="0" width="47%" className="bilgi_alani_yuzde_47" align="left">
                                <tbody>
                                  <tr>
                                    <td className="bilgi_alani_yazi_tablosu_disi">
                                      <table cellPadding="0" cellSpacing="0" border="0" width="100%">
                                        <tbody>
                                          <tr>
                                            <td align="left" className="bilgi_alani_yazi_baslik">
                                              {_yazilar.alici_unvan}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table cellPadding="0" cellSpacing="0" border="0" width="47%" className="bilgi_alani_yuzde_47" align="right">
                                <tbody>
                                  <tr>
                                    <td className="bilgi_alani_yazi_tablosu_disi">
                                      <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="bilgi_alani_sifirlama">
                                        <tbody>
                                          <tr>
                                            <td align="right" className="bilgi_alani_yazi_baslik">
                                              {_belge.alici_unvan}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table cellSpacing="0" cellPadding="0" border="0" width="100%" className="bilgi_alani_sifirlama">
                        <tbody>
                          <tr>
                            <td valign="top" className="bilgi_alani_yazi_baslik_disi mobile-wrapper">
                              <table cellPadding="0" cellSpacing="0" border="0" width="47%" className="bilgi_alani_yuzde_47" align="left">
                                <tbody>
                                  <tr>
                                    <td className="bilgi_alani_yazi_tablosu_disi">
                                      <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="bilgi_alani_sifirlama">
                                        <tbody>
                                          <tr>
                                            <td align="left" className="bilgi_alani_yazi_baslik">
                                              {_yazilar.alici_vergi_dairesi}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table cellPadding="0" cellSpacing="0" border="0" width="47%" className="bilgi_alani_yuzde_47" align="right">
                                <tbody>
                                  <tr>
                                    <td className="bilgi_alani_yazi_tablosu_disi">
                                      <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="bilgi_alani_sifirlama">
                                        <tbody>
                                          <tr>
                                            <td align="right" className="bilgi_alani_yazi_baslik">
                                              {_belge.alici_vergi_dairesi}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table cellSpacing="0" cellPadding="0" border="0" width="100%" className="bilgi_alani_sifirlama">
                        <tbody>
                          <tr>
                            <td valign="top" className="bilgi_alani_yazi_baslik_disi mobile-wrapper">
                              <table cellPadding="0" cellSpacing="0" border="0" width="47%" className="bilgi_alani_yuzde_47" align="left">
                                <tbody>
                                  <tr>
                                    <td className="bilgi_alani_yazi_tablosu_disi">
                                      <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="bilgi_alani_sifirlama">
                                        <tbody>
                                          <tr>
                                            <td align="left" className="bilgi_alani_yazi_baslik">
                                              {_yazilar.alici_vergi_numarasi}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table cellPadding="0" cellSpacing="0" border="0" width="47%" className="bilgi_alani_yuzde_47" align="right">
                                <tbody>
                                  <tr>
                                    <td className="bilgi_alani_yazi_tablosu_disi">
                                      <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="bilgi_alani_sifirlama">
                                        <tbody>
                                          <tr>
                                            <td align="right" className="bilgi_alani_yazi_baslik">
                                              {_belge.alici_vergi_numarasi}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table border="0" cellPadding="0" cellSpacing="0" width="100%" className="bilgi_alani_tablolar responsive-table">
                <tbody>
                  <tr>
                    <td className="bilgi_alani_bolum_2">
                      <table cellSpacing="0" cellPadding="0" border="0" width="100%">
                        <tbody>
                          <tr>
                            <td valign="top" className="bilgi_alani_ust_sinir_ici mobile-wrapper">
                              <table cellPadding="0" cellSpacing="0" border="0" width="47%" className="bilgi_alani_yuzde_47" align="left">
                                <tbody>
                                  <tr>
                                    <td className="bilgi_alani_yazi_tablosu_disi">
                                      <table cellPadding="0" cellSpacing="0" border="0" width="100%">
                                        <tbody>
                                          <tr>
                                            <td align="left" className="bilgi_alani_sonuc_yazi_4">
                                              {_yazilar.cari_bakiye}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table cellPadding="0" cellSpacing="0" border="0" width="47%" className="bilgi_alani_yuzde_47" align="right">
                                <tbody>
                                  <tr>
                                    <td className="bilgi_alani_yazi_tablosu_disi">
                                      <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="bilgi_alani_sifirlama">
                                        <tbody>
                                          <tr>
                                            <td align="right" className="bilgi_alani_sonuc_yazi_3">
                                              {_belge.cari_bakiye} {_belge.para_birimi}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td className="bilgi_alani_bolum_3">
                      <table cellSpacing="0" cellPadding="0" border="0" width="100%" className="bilgi_alani_sifirlama">
                        <tbody>
                          <tr>
                            <td valign="top" className="bilgi_alani_yazi_baslik_disi mobile-wrapper">
                              <table cellPadding="0" cellSpacing="0" border="0" width="47%" className="bilgi_alani_yuzde_47" align="left">
                                <tbody>
                                  <tr>
                                    <td className="bilgi_alani_yazi_tablosu_disi">
                                      <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="bilgi_alani_sifirlama">
                                        <tbody>
                                          <tr>
                                            <td align="left" className="bilgi_alani_sonuc_yazi_1">
                                              {_yazilar.hesap_tipi}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <table cellPadding="0" cellSpacing="0" border="0" width="47%" className="bilgi_alani_yuzde_47" align="right">
                                <tbody>
                                  <tr>
                                    <td className="bilgi_alani_yazi_tablosu_disi">
                                      <table cellPadding="0" cellSpacing="0" border="0" width="100%" className="bilgi_alani_sifirlama">
                                        <tbody>
                                          <tr>
                                            <td align="right" className="bilgi_alani_sonuc_yazi_2">
                                              {_belge.hesap_tipi}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td bgcolor="#ffffff" align="center" className="butonlar_alani">
              <table border="0" cellPadding="0" cellSpacing="0" width="500" className="butonlar_alani_tablolari responsive-table">
                <tbody>
                  <tr>
                    <td className="butonlar_alani_tablolari_ici">
                      <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="butonlar_alani_tablolari">
                        <tbody>
                          <tr>
                            <td align="left" className="butonlar_alani_notlar padding-copy">
                              {renderHTML(_yazilar.notlar)}
                            </td>
                          </tr>
                          <tr>
                            <td align="left" className="butonlar_alani_notlar padding-copy">
                              <div className="form_baslik">
                                IP: <strong>{this.state.ip}</strong>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td align="left" className="butonlar_alani_notlar padding-copy">
                              <div className="form_baslik">
                                {this.state.yazilar.tr.isim_soyisim} {this.state.isim_alani_zorunlu && !this.state.isim && <span style={{ color: "#cc0000" }}>{this.state.yazilar.tr.gerekli_alan}</span>}
                              </div>
                              <input required={true} type="text" onChange={this._isimAlani} className={this.state.isim_alani_zorunlu && !this.state.isim ? "yazi_alani gerekli" : "yazi_alani"} placeholder="İsim soyisim yazın." />
                            </td>
                          </tr>
                          <tr>
                            <td align="left" className="butonlar_alani_notlar padding-copy">
                              <div className="form_baslik">
                                Dosya {this.state.dosya_yukleniyor && <FontAwesomeIcon color="#cc0000" icon={faSpinner} spin size="2x" />}
                                {this.state.dosya_yukleme_hatasi && <span className="dosya_yukleme_hatasi">{this.state.dosya_yukleme_hatasi}</span>}
                              </div>
                              <input type="file" accept={this.state.dosya_tipleri.reduce((a, b, i) => (i === 0 ? `.${b}` : `${a},.${b}`), "")} onChange={this._dosyaAlani} className="yazi_alani" />
                            </td>
                          </tr>
                          <tr>
                            <td align="left" className="butonlar_alani_notlar padding-copy">
                              <textarea onChange={this._notAlani} className="not_alani" placeholder="Mutabakat ile ilgili notlarınızı yazın."></textarea>
                            </td>
                          </tr>
                          <tr>
                            <td align="center" className="butonlar_alani_tablolari_ici">
                              <table width="100%" border="0" cellSpacing="0" cellPadding="5" className="butonlar_alani_tablolari">
                                <tbody>
                                  <tr>
                                    <td align="center" className="butonlar_alani_buton_sutunlari">
                                      <table border="0" cellSpacing="0" cellPadding="0" className="butonlar_alani_tablolari mobile-button-container">
                                        <tbody>
                                          <tr>
                                            <td
                                              onClick={() => {
                                                if (this.state.isim_alani_zorunlu && !this.state.isim) {
                                                  alert(this.state.yazilar.tr.isim_soyisim_zorunlu);
                                                  return;
                                                }

                                                !this.state.dosya_yukleniyor && this.mutabakataCevapVer({ belge_id: this.props.match.params.belge_id, cevap: "1", musteri_id: this.props.match.params.musteri_id });
                                              }}
                                              align="center"
                                              className="butonlar_alani_buton_kutulari"
                                              bgcolor="#0CCE6B"
                                            >
                                              <span className="butonlar_alani_onay_butonu mobile-button">{_yazilar.mutabikiz}</span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td align="center" className="butonlar_alani_buton_sutunlari">
                                      <table border="0" cellSpacing="0" cellPadding="0" className="butonlar_alani_tablolari mobile-button-container">
                                        <tbody>
                                          <tr>
                                            <td
                                              onClick={() => {
                                                if (this.state.isim_alani_zorunlu && !this.state.isim) {
                                                  alert(this.state.yazilar.tr.isim_soyisim_zorunlu);
                                                  return;
                                                }

                                                !this.state.dosya_yukleniyor && this.mutabakataCevapVer({ belge_id: this.props.match.params.belge_id, cevap: "2", musteri_id: this.props.match.params.musteri_id });
                                              }}
                                              align="center"
                                              className="butonlar_alani_buton_kutulari"
                                              bgcolor="#DB4437"
                                            >
                                              <span className="butonlar_alani_red_butonu mobile-button">{_yazilar.mutabik_degiliz}</span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };
  _dosyaAlani = (dosya) => {
    this.setState({ dosya: dosya.target.files[0], dosya_yukleniyor: true }, this.dosyaYukle);
  };
  _isimAlani = (isim) => {
    this.setState({ isim: isim.target.value });
  };
  _notAlani = (not) => {
    this.setState({ belge: { ...this.state.belge, not: not.target.value } });
  };
  _sonuc = (durum) => {
    const { tr: _yazilar } = this.state.yazilar;

    return (
      <table border="0" cellPadding="0" cellSpacing="0" width="100%" className="ana_tablo">
        <tbody>
          <tr>
            <td bgcolor="#ffffff" align="center" className="ana_tablo_arkaplan">
              <table border="0" cellPadding="0" cellSpacing="0" width="100%" className="icerik_tablosu wrapper">
                <tbody>
                  <tr>
                    <td align="center" valign="top" className="icerik_tablosu_bosluklari logo">
                      <img alt="Logo" src={`https://emutabakat.dijitalgozluk.com/gorseller/logo-${this.props.match.params.musteri_id}.png`} onError={this.olmayanGorsel} className="logo-gorseli" border="0" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td bgcolor="#ffffff" align="center" className="sayfa_basligi_tablosu_disi">
              <table border="0" cellPadding="0" cellSpacing="0" width="100%" className="sayfa_basligi_tablosu responsive-table">
                <tbody>
                  <tr>
                    <td>
                      <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                        <tbody>
                          <tr>
                            <td align="center" className="sayfa_basligi_tablosu_ana_baslik padding-copy">
                              {_yazilar.duruma_gore_yazilar["durum_" + durum].baslik}
                            </td>
                          </tr>
                          <tr>
                            <td align="center" className="sayfa_basligi_tablosu_alt_baslik padding-copy">
                              {_yazilar.duruma_gore_yazilar["durum_" + durum].mesaj}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    );
  };
  _yukleniyor = () => {
    return (
      <div className="yukleniyor">
        <FontAwesomeIcon className="yukleniyor-ikonu" icon={faCog} spin />
      </div>
    );
  };
}

export default EMutabakat;
